<template>
  <div class="section--amenity">
    <div class="row">
      <div class="col-12 col-md-6" v-for="(item, k) in amenities" :key="k">
        <ul class="amenity--item ma-0">
          <li>{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    amenities: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped></style>
